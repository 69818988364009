import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import Section from '../Section';
import { ISection, SectionTypes } from '../../types';
import { SchedulingAgenda } from '../../../BookingSchedulingAgenda';
import { classes } from './Scheduling.st.css';
import SectionTitle from '../SectionTitle/SectionTitle';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { SchedulingSectionViewModel } from '../../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { TimezoneType } from '@wix/bookings-uou-types';
import SectionText from '../SectionText/SectionText';
import TimezoneIndication from '../TimezoneIndication/TimezoneIndication';

export type SchedulingProps = InjectedTranslateProps & {
  section: ISection;
  className?: string;
  schedule?: SchedulingSectionViewModel;
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void;
};

const CourseSummary = ({
  schedule,
  t,
}: {
  schedule: SchedulingSectionViewModel;
  t: any;
}) => {
  const { firstSessionDate, lastSessionDate } = schedule;
  const numberOfSessions = schedule.schedulingDaysViewModel?.length;
  return firstSessionDate && lastSessionDate ? (
    <div className={classes.courseSummary} data-hook={'course-summary'}>
      <SectionText
        dataHook={'scheduling-dates'}
        text={t('app.scheduling.course-summary.dates', {
          firstSessionDate,
          lastSessionDate,
        })}
      />
      <SectionText
        dataHook={'scheduling-number-of-sessions'}
        text={t('app.scheduling.course-summary.total-number-of-sessions', {
          numberOfSessions,
        })}
      />
    </div>
  ) : null;
};

const SchedulingComponent = ({
  t,
  section,
  className,
  schedule,
  changeTimezoneCallback,
}: SchedulingProps) => {
  const settings = useSettings();
  return (
    <Section
      sectionType={SectionTypes.SCHEDULING}
      data-hook="scheduling-section"
      withBookButton={schedule?.isBookable && section.bookButton}
    >
      <div className={classes.agendaWrapper}>
        <SectionTitle
          title={
            settings.get(settingsParams.scheduleSectionTitle) ||
            t('app.scheduling.title.default')
          }
          dataHook="scheduling-title"
          className={className}
        />
        {schedule?.schedulingDaysViewModel && (
          <CourseSummary schedule={schedule} t={t} />
        )}
        <SchedulingAgenda viewModel={schedule} />
        {schedule?.canChangeTimezone && (
          <TimezoneIndication
            changeTimezoneCallback={changeTimezoneCallback!}
            currentTimezone={schedule.viewTimezone!}
            currentTimezoneType={schedule.viewTimezoneType!}
            timezoneDropdownOptions={schedule.timezoneDropdownOptions!}
          />
        )}
      </div>
    </Section>
  );
};

export default translate()(SchedulingComponent);
