import React from 'react';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './Details.st.css';
import { AlignmentOptions, ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { DetailsSectionViewModel } from '../../../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { useGlobalAlignment } from '../useGlobalAlignment';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';

type DetailsProps = InjectedTranslateProps & {
  viewModel?: DetailsSectionViewModel;
  section: ISection;
  className?: string;
};

const DetailsComponent: React.FC<DetailsProps> = ({
  viewModel,
  section,
  className,
}) => {
  const { mobile = false } = useTPAComponentsConfig();
  const settings = useSettings();
  const globalAlignment = useGlobalAlignment();
  const buttonAlignment =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsButtonAlignment)
      : settings.get(settingsParams.detailsBoxAlignment);
  const textAlignment =
    (settings.get(settingsParams.detailsBoxAlignment) !==
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsBoxAlignment)
      : settings.get(settingsParams.detailsTextAlignment)) || globalAlignment;
  const boxWidth =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? '100%'
      : undefined;
  const withBookButton = section.bookButton && viewModel?.isBookable;
  const showDaysOffered =
    settings.get(settingsParams.detailsDaysVisibility) &&
    viewModel?.daysOffered;
  const showDuration =
    settings.get(settingsParams.detailsDurationVisibility) &&
    viewModel?.duration;
  const showPrice =
    settings.get(settingsParams.detailsPriceVisibility) && viewModel?.price;
  const showLocation =
    settings.get(settingsParams.detailsLocationVisibility) &&
    viewModel?.location;

  const content: JSX.Element[] = [];
  if (showDaysOffered) {
    content.push(
      <Text
        key="details-days-offered"
        className={classes.box}
        data-hook="details-days-offered"
        tagName={'li'}
      >
        {viewModel?.daysOffered}
      </Text>,
    );
  }
  if (showDuration) {
    content.push(
      <Text
        key="details-duration"
        className={classes.box}
        data-hook="details-duration"
        tagName={'li'}
      >
        {viewModel?.duration}
      </Text>,
    );
  }
  if (showPrice) {
    content.push(
      <Text
        key="details-price"
        className={classes.box}
        data-hook="details-price"
        tagName={'li'}
      >
        {viewModel?.price}
      </Text>,
    );
  }
  if (showLocation) {
    content.push(
      <Text
        key="details-location"
        className={classes.box}
        data-hook="details-location"
        tagName={'li'}
      >
        {viewModel?.location}
      </Text>,
    );
  }

  return withBookButton || !!content.length ? (
    <Section
      sectionType={SectionTypes.DETAILS}
      withBookButton={withBookButton}
      buttonAlignment={buttonAlignment}
      childrenAlignment={settings.get(settingsParams.detailsBoxAlignment)}
    >
      <div
        style={{ width: boxWidth }}
        className={st(classes.root, { textAlignment, mobile }, className)}
        data-hook="details-wrapper"
      >
        {content}
      </div>
    </Section>
  ) : null;
};

export default translate()(DetailsComponent);
